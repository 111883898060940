import React, { Component } from 'react';

// import components
import VirtualizedSelect from 'react-virtualized-select';
import Notifications, {notify} from 'react-notify-toast';
import Favicon from 'react-favicon';

// import image assets
import logo from './logo.png';
import logoMain from './logo-main.png';

// import style sheets
import './App.css';
import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';

// import config json
import packageJSON from '../package.json';
import config from './App.js.config.json';

//const originsURL = 'https://assets.asosservices.com/landingpage/origins.json'

// Instantiate query-string package
const querystring = require('query-string');

// Parse the full URL to strip off query string parameters and return just the path
function getPathFromUrl(url) {
  return url.split("?")[0];
}

// Parse the URL and return the hostname we're served under
function getHostname(){
  const parsed = querystring.parse(window.location.search);
  if ('hostname' in parsed) {
    console.log('parsed query string:', window.location.search, 'to use a domain of:', parsed.hostname);
    return parsed.hostname;
  } else {
    const fqdn = window.location.host;
    //const arrFqdn = fqdn.split('.');
    //const topdomain = arrFqdn[0];
    console.log('Parsed:', window.location.host, 'to use domain of:', fqdn);
    //return topdomain;
    return fqdn;
  }
}

class App extends Component {

  // Initialise state
  constructor(props) {
    super(props);
    this.state = {
      selectValue: '',
      origins : {}
    };

    // Bind Origins
    this.getOrigins = this.getOrigins.bind(this);
    this.setOrigins = this.setOrigins.bind(this);

    // Create Notification queue, where notification message are placed
    this.show = notify.createShowQueue();

    console.log(packageJSON.name, 'version', packageJSON.version);
  }

  // Issue HTTP GET request to external origins json resource
  getOrigins() {
    fetch(config.originsURL)
    .then(response => response.json())
    .then(origins => this.setOrigins(origins))
    .catch(e => console.log(e));
  }

  setOrigins(origins) {
    this.setState({ origins });
  }

  componentDidMount() {
    this.getOrigins();
  }

  // redirect the browser
  redirect = (e) => {
    console.log('button clicked', this.state.selectValue.value);

    if (this.state.selectValue === '') {
      console.log('No environment selected');
    } else {
      this.show('Opening: ' + getPathFromUrl(document.URL) + '?x-site-origin=' + this.state.selectValue.value, 'success', 4000);
      console.log('The following x-site-origin will be used:', this.state.selectValue.value);
      // window.location.href will reuse the same browser tab/window
      // window.location.href = getPathFromUrl(document.URL) + '?x-site-origin=' + this.state.selectValue.value;
      // window.open will open a new browser tab/window
      // window.open(getPathFromUrl(document.URL) + '?x-site-origin=' + this.state.selectValue.value);

      //Launch URL
      window.location.href = getPathFromUrl(document.URL) + '?x-site-origin=' + this.state.selectValue.value;
    }
  }

  render() {
    // Get the hostname portion of the FQDN being used
    var hostname = getHostname();
    // Return only the json array of origins for the hostname
    var options = {};


    // Check if our json contains origin entries for the hostname
    if (this.state.origins[hostname]) {
      // We've got something, so an array with the hostname exists.  Now count its elements
      const originCount = Object.keys(this.state.origins[hostname]).length;
      console.log('There are ' + originCount + ' origins configured for ' + hostname);
      // Make sure we've got at least one entry in the hostname's array
      if (originCount > 0) {
        // Return only the json array of origins for the hostname
        options = this.state.origins[hostname];
      } else {
        // The origin array for the hostname is empty, so use the default hostname
        options = this.state.origins[config.defaultHostname];
      }
    } else {
      // An array for the hostname doesn't exist, so uae the default hostname
      options = this.state.origins[config.defaultHostname];
    }

    let notifyColour = { background: '#FFFFFF', text: "#23b9e7" };

    return (
      <div className="App">
        <Notifications />
        <Favicon url="/favicon.ico" />
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo"/>
          <img src={logoMain} className="App-main-logo" alt="main logo" />
          <h1 className="App-title">Asos non-production [{ getHostname() }]</h1>
        </header>
        <p className="App-intro">
          Select an environment, or start typing to filter the options

        </p>

        <span className = "App-dropdownContainer">

          <VirtualizedSelect
            options={options}
            onChange={(selectValue) => {
              this.setState({ selectValue });
              if (selectValue !== null){
                console.log('Origin selected:', selectValue.value);
                this.show('x-site-origin: ' + selectValue.value, 'custom', 5000, notifyColour);
              }
            }}
            value={this.state.selectValue}
          />
        </span>
        <p className = "App-GoButtonContainer">
          <button className = "App-GoButton" onClick={this.redirect}>Go</button>
        </p>

      </div>
    );
  }
}

export default App;